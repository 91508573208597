<template>
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">
            <el-button type="primary" size="mini" @click="add">添加推荐</el-button> 
        </div>
        <el-table
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column label="id" prop="id" fixed></el-table-column>
            <el-table-column fixed label="主图">
    <template slot-scope="scope">
                    <el-image :src="scope.row.main_img" :preview-src-list="[scope.row.main_img]"></el-image>
                </template>
</el-table-column>
<el-table-column fixed prop="title" label="主标题">
</el-table-column>
<el-table-column prop="subtitle" label="副标题">
</el-table-column>
<el-table-column prop="label_1" label="标签1">
</el-table-column>
<el-table-column prop="label_2" label="标签2">
</el-table-column>
<el-table-column prop="trigger_times" label="触发次数">
</el-table-column>
<el-table-column prop="click_times" label="点击次数">
</el-table-column>
<el-table-column prop="button_copy" label="状态">
    <template slot-scope="scope">
        {{scope.row.status | statusType}}
    </template>
</el-table-column>
<el-table-column fixed="right" label="操作" width="200">
    <template slot-scope="scope">
                    <el-button :type="scope.row.status==1?'danger':'primary'" size="mini" @click="updateStatus(scope.row.id,scope.row.status)">{{scope.row.status==1?'停用':'启用'}}</el-button>
                    <el-button type="primary" size="mini" @click="alret(scope.row.id)">修改</el-button>
                </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>

<recommendDialog :showExport="show" @clickClose="close" :platform="platform"></recommendDialog>
</section>
<!-- /.content -->
</template>

<script>
    import * as api from '@/config/api'
    import {
        md5
    }
    from '@/config/md5'
    export default {
        name: 'platform',
        components: {},

        data() {
            return {
                tableData: [],
                total: 0,
                show: false,
                page: 1,
                pagesize: 10,
                platform: {},
                loading: true
            }
        },
        watch: {},
        filters: {
            statusType(val) {
                let status = ''
                switch (Number(val)) {
                    case 1:
                        status = '启用'
                        break;

                    case 2:
                        status = '停用'
                        break;
                }
                return status
            }
        },
        mounted() {
            this.getRecommendList()
        },
        methods: {
            close() {
                this.show = false
                this.getRecommendList()
            },
            getRecommendList() {
                this.loading = true
                api.getRecommendList({
                    page: this.page,
                    pagesize: this.pagesize
                }, res => {
                    this.tableData = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getRecommendList()
            },
            currentChange(val) {
                this.page = val
                this.getRecommendList()
            },
            copy() {
                this.$message({
                    message: '复制成功',
                    type: 'success'
                })
            },
            status(id, statusType) {
                api.updateRecommendStatus({
                    id: id,
                    status: statusType == 1 ? 2 : 1
                }, res => {
                    this.$message({
                        type: 'success',
                        message: statusType === 1 ? '推荐已停用!' : '推荐已启用！'
                    });
                    this.getRecommendList()
                })
            },
            add() {
                this.platform = {
                    status: 1,
                    match_mode: 2,
                    audit_mode: 2
                }
                this.show = true;
            },
            updateStatus(id, statusType) {
                if (statusType == 1) {
                    this.$confirm('推荐通用后不可恢复，你还要继续吗？', '请确定是否停用推荐', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.status(id, statusType)
                    }).catch(() => {})
                    return
                } else {
                    this.status(id, statusType)
                }
            },
            del(id) {
                this.$confirm('平台删除后不可恢复，你还要继续吗？', '请确定是否删除平台', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.delRecommend({
                        id: id
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getRecommendList()
                    })
                }).catch(() => {})
            },
            alret(id) {
                api.getRecommend({
                    id: id
                }, res => {
                    this.platform = res.data.item
                    this.show = true
                })
            },
        }
    }
</script>
<style scoped>
    .el-table .cell {
        text-align: center;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>